<template>
  <div>
    <div class="marquee-container" v-if="sliderData[0]">
      <v-row class="my-1" :class="locale === 'he' ? 'marquee-content-he' : 'marquee-content'" style="height: 35px">
        <span v-html="formattedText" :class="locale === 'he' ? 'he-content' : 'en-content'" style="background-color: lightblue; font-size: 22px"></span>
      </v-row>
    </div>
    <v-row dense class="panel-group">
      <panel-group-item
        :title="$t('dashboard.quotations')"
        icon="mdi-file-delimited-outline"
        color="orange"
        chart="quotations"
        :start="0"
        :end="102400"
        :duration="2600"
        :chartType="chartType"
      />
      <panel-group-item
        :title="$t('dashboard.orders')"
        icon="mdi-note-multiple-outline"
        color="primary"
        chart="orders"
        :start="0"
        :end="81212"
        :duration="3000"
        :chartType="chartType"
      />
      <panel-group-item
        :title="$t('dashboard.invoices')"
        icon="mdi-note-text-outline"
        color="blue-grey"
        chart="invoices"
        :start="0"
        :end="9280"
        :duration="3200"
        :chartType="chartType"
      />
      <panel-group-item
        :title="$t('dashboard.receipts')"
        icon="mdi-receipt-outline"
        color="green"
        chart="receipts"
        :start="0"
        :end="13600"
        :duration="3600"
        :chartType="chartType"
      />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PanelGroupItem from "./PanelGroupItem.vue";

export default {
  name: "PanelGroup",
  props: ["chartType"],
  components: {
    PanelGroupItem,
  },
  data() {
    return {
      sliderData: []
    }
  },
  computed: {
    formattedText() {
      if (this.locale === 'he') {
        return `<span dir="ltr">${this.sliderData[0]}</span>`;
      } else {
        const segments = this.sliderData[0].split(/(<[^>]*>)/);
        const reversedSegments = segments.map((segment) => {
          if (segment.startsWith('<')) {
            return segment;
          } else {
            return segment.split(' ').reverse().join(' ');
          }
        });
        const reversedText = reversedSegments.join('');
        return reversedText;
      }
    },
    ...mapGetters({
      locale: 'locale',
    }),
  },
  mounted() {
    this.$store.dispatch('GetNews').then((res) => {
      this.sliderData = res.map(item => item.description)
    })
  },
};
</script>

<style scoped>
.marquee-container {
  width: 100%;
  overflow: hidden;
}
.marquee-content {
  white-space: nowrap;
  animation: marquee 25s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.marquee-content-he {
  white-space: nowrap;
  animation: marquee-he 25s linear infinite;
}
@keyframes marquee-he {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
::v-deep .he-content p {
  direction: ltr;
}
</style>
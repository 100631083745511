<template>
  <v-list-item
    v-if="accessRight.includes('create')"
    dense
    class="primary--text"
    @click="loginGoogle"
  >
    <v-list-item-content>
      <v-list-item-title>
        <v-icon
          v-if="profileById.calander_type === 'google'"
          small
          class="primary--text"
        >
          mdi-google
        </v-icon>
        <v-icon v-else small class="primary--text">
          mdi-microsoft-outlook
        </v-icon>
        {{
          profileById.calander_type === "google"
            ? $t("calendar.google")
            : $t("calendar.outlook")
        }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoginGoogle",
  async mounted() {
    await this.$store.dispatch("profile/GetCompanyById");
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight",
      user: "user",
      profileById: "profile/profileById",
    }),
  },
  methods: {
    async loginGoogle() {
      const url = window.location.href;
      const company_name = url.split(".")[0].split("//")[1];
      if (this.profileById.calander_type === "google") {
        await this.$store
          .dispatch(
            "calendar/GoogleLogin",
            `?empId=${this.user.id}&tenant=${company_name}&url=${url}`
          )
          .then((res) => {
            window.open(res.url);
          });
      } else {
        await this.$store
          .dispatch(
            "calendar/OutlookLogin",
            `?empId=${this.user.id}&tenant=${company_name}&url=${url}`
          )
          .then((res) => {
            window.open(res.url);
          });
      }
    },
  },
};
</script>

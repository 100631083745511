<template>
  <div>
    <!-- <github-corner class="github-corner" /> -->
    <v-container class="container--fluid grid-list-md text-center">
      <panel-group
        :chartType="chartType"
        @handleSetLineChartData="handleSetLineChartData"
      />
      <v-row dense>
        <v-col cols="12">
          <v-card class="mx-auto" outlined>
            <v-card-title class="py-1">
              <v-row dense no-gutters>
                <v-col
                  cols="3"
                  class="text-start quotations-box-text"
                  v-if="xAxisMonths.length > 0"
                >
                  <h5
                    class="my-4 text-h6 primary--text font-weight-bold text-capitalize"
                  >
                    {{ $t("dashboard." + chartType) }}
                  </h5>
                  <v-switch
                    hide-details="auto"
                    v-model="barChart"
                    class="float-end me-2"
                  >
                    <template #prepend>
                      <v-icon
                        @click="barChart = false"
                        :class="
                          !barChart
                            ? 'primary--text'
                            : 'primary--text text--darken-1'
                        "
                        >mdi-chart-bell-curve</v-icon
                      >
                    </template>
                    <template #append>
                      <v-icon
                        @click="barChart = true"
                        :class="
                          barChart
                            ? 'primary--text'
                            : 'primary--text text--darken-1'
                        "
                        >mdi-chart-bar</v-icon
                      >
                    </template>
                  </v-switch>
                </v-col>

                <v-col cols="9" class="text-end d-flex">
                  <v-autocomplete
                    hide-details="auto"
                    v-model="selectedEmployee"
                    :items="employeeList.data"
                    item-text="name"
                    item-value="id"
                    clearable
                    class="me-2"
                    return-object
                    single-line
                    :search-input.sync="employeeSearch"
                    :label="$t('employee.employee')"
                    @input="calculateChartData()"
                  >
                    <template v-slot:append-item>
                      <div
                        v-show="hasEmployeeNextPage"
                        v-intersect="infiniteScroll"
                        ref="load"
                        class="loader text-center"
                      >
                        <v-progress-circular indeterminate color="primary" />
                      </div>
                    </template>
                  </v-autocomplete>
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="filter_date"
                    transition="scale-transition"
                    offset-y
                    :nudge-left="40"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        class="me-2"
                        single-line
                        v-model="formattedFilterDate"
                        :label="$t('invoice.from')"
                        v-bind="attrs"
                        v-on="on"
                        @input="calculateChartData()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter_date"
                      @input="calculateChartData()"
                      @change="$refs.startDateMenu.save(filter_date)"
                    >
                      <v-btn
                        small
                        class="primary"
                        @click="$refs.startDateMenu.save(todayDate())"
                        >{{ $t("route.today") }}</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                  <v-autocomplete
                    hide-details="auto"
                    class="me-2 multi-select-field"
                    v-model="selectedCustomerGroup"
                    :items="customerGroupValue"
                    item-text="description"
                    item-value="id"
                    clearable
                    return-object
                    multiple
                    allow-overflow
                    small-chips
                    deletable-chips
                    chips
                    single-line
                    :label="$t('customer.field.group')"
                    @input="calculateChartData()"
                  />
                  <v-autocomplete
                    hide-details="auto"
                    class="me-2 multi-select-field"
                    v-model="selectedItemGroup"
                    :items="itemGroupValue"
                    item-text="description"
                    item-value="id"
                    clearable
                    return-object
                    multiple
                    allow-overflow
                    small-chips
                    deletable-chips
                    chips
                    single-line
                    :label="$t('product.field.group')"
                    @input="calculateChartData()"
                  />
                  <v-menu bottom :close-on-click="true" :offset-y="true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        class="ma-2 mt-5"
                        small
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon left> mdi-menu-down </v-icon>
                        {{ $t("calendar.calendarEvents") }}
                      </v-btn>
                    </template>
                    <v-list>
                      <LoginGoogle />
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text class="d-flex text-h5 pa-2">
              <option-chart
                class="d-inline"
                v-if="xAxisMonths.length > 0"
                slot="widget-content"
                height="400px"
                width="50%"
                :chart-data="getChartOption(!barChart ? 'line' : 'bar')"
              />
              <personal-menu class="d-inline" />
              <customer-support class="d-inline" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="my-2">
        <v-col cols="9">
          <CalendarList />
        </v-col>
      </v-row>
      <WelcomeDialog :dialog.sync="dialog" />

      <!-- <LoginGoogle /> -->
    </v-container>
  </div>
</template>

<script>
import { getLineChartOption, getAuxiliaryZtableValueByName } from "@/utils";
import PanelGroup from "@/views/companyApp/Dashboard/PanelGroup.vue";
import OptionChart from "@/views/companyApp/Dashboard/OptionChart.vue";
// import AppWidget from '@/views/widget/AppWidget.vue';
import WelcomeDialog from "@/components/WelcomeDialog";
import { mapGetters } from "vuex";
import { formatDateDDMMYYYY, todayDate } from "../../../utils";
import PersonalMenu from "./PersonalMenu.vue";
import CustomerSupport from "./CustomerSupport.vue";
import CalendarList from "./CalendarList.vue";
import { intersectionMixin } from "@/mixins/intersectionMixin";
import { searchMixin } from "@/mixins/searchMixin";
import LoginGoogle from "@/components/LoginGoogle";

export default {
  name: "Dashboard",
  components: {
    // GithubCorner,
    PanelGroup,
    OptionChart,
    // PlainTable,
    // PlainTableOrder,
    // AppWidget,
    WelcomeDialog,
    PersonalMenu,
    CustomerSupport,
    CalendarList,
    LoginGoogle,
  },
  mixins: [intersectionMixin, searchMixin],
  data: () => ({
    chartType: null,
    xAxisMonths: [],
    employeePage: 1,
    employeeLimit: 10,
    selectedEmployee: {
      id: null,
    },
    selectedCustomerGroup: [],
    customerGroupValue: [],
    selectedItemGroup: [],
    itemGroupValue: [],
    startDateMenu: false,
    filter_date: new Date().toISOString().substring(0, 10),
    formattedFilterDate: "",
    lineChartData: {
      quotations: {
        sumOfAllDoc: [],
        sumOfClosedDoc: [],
        sumOfCancelledDoc: [],
      },
      orders: {
        sumOfAllDoc: [],
        sumOfClosedDoc: [],
        sumOfCancelledDoc: null,
      },
      invoices: {
        sumOfAllDoc: [],
        sumOfClosedDoc: null,
        sumOfCancelledDoc: [],
      },
      receipts: {
        sumOfAllDoc: [],
        sumOfClosedDoc: null,
        sumOfCancelledDoc: null,
      },
    },
  }),
  computed: {
    chartLegend() {
      return {
        quotations: [
          this.$t("dashboard.sumOfAllQuotations"),
          this.$t("dashboard.sumOfClosedQuotations"),
          this.$t("dashboard.sumOfCancelledQuotations"),
        ],
        orders: [
          this.$t("dashboard.sumOfAllOrders"),
          this.$t("dashboard.sumOfClosedOrders"),
          null,
        ],
        invoices: [
          this.$t("dashboard.sumOfAllInvoices"),
          null,
          this.$t("dashboard.sumOfInvoicesWithUnpaidBalance"),
        ],
        receipts: [this.$t("dashboard.sumOfAllReceipts"), null, null],
      };
    },
    ...mapGetters({
      quotationList: "quotation/quotationList",
      orderList: "order/orderList",
      orderById: "order/orderById",
      invoiceList: "invoice/invoiceList",
      receiptList: "receipt/receiptList",
      employeeList: "employee/employeeList",
      customerList: "customer/customerList",
      locale: "locale",
      welcomeDialog: "welcomeDialog",
      selectedBarChartType: "selectedBarChartType",
      profileById: "profile/profileById",
    }),
    dialog: {
      get() {
        return this.welcomeDialog;
      },
      set(val) {
        this.$store.dispatch("SetWelcomeDialog", val);
      },
    },
    barChart: {
      get() {
        return this.selectedBarChartType;
      },
      set(val) {
        this.$store.commit("SET_CHART_TYPE", val);
      },
    },
  },
  watch: {
    filter_date(val) {
      this.formattedFilterDate = this.profileById.date_format
        ? val
        : formatDateDDMMYYYY(val);
    },
    profileById(val) {
      this.formattedFilterDate = val.date_format
        ? this.filter_date
        : formatDateDDMMYYYY(this.filter_date);
    },
    locale() {
      this.calculateChartData();
    },
    employeeLimit() {
      this.$store.dispatch("employee/GetEmployee", {
        page: 1,
        limit: this.employeeLimit,
        order_by: "name|asc",
      });
    },
    chartType() {
      this.calculateChartData();
    },
  },
  async mounted() {
    this.chartType = "quotations";
    await this.$store.dispatch("profile/GetCompanyById");
    await this.$store.dispatch("employee/GetEmployee", {
      page: this.employeePage,
      limit: this.employeeLimit,
      order_by: "name|asc",
    });
    let tableNameValue = await getAuxiliaryZtableValueByName(2);
    if (Object.keys(tableNameValue).length > 0) {
      this.customerGroupValue = tableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    let itemNameValue = await getAuxiliaryZtableValueByName(3);
    if (Object.keys(itemNameValue).length > 0) {
      this.itemGroupValue = itemNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
  },
  methods: {
    todayDate,
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    handleSetLineChartData(type) {
      this.chartType = type;
    },
    getChartOption(option) {
      switch (option) {
        case "line":
          return getLineChartOption(
            this.chartLegend[this.chartType],
            this.xAxisMonths,
            "line",
            this.lineChartData[this.chartType]
          );
        case "bar":
          return getLineChartOption(
            this.chartLegend[this.chartType],
            this.xAxisMonths,
            "bar",
            this.lineChartData[this.chartType]
          );
        default:
          return {};
      }
    },
    async calculateChartData() {
      // set charts x axis data based on selected date
      this.xAxisMonths = [];
      for (let i = 11; i >= 0; i--) {
        let now = new Date(this.filter_date);
        let newdate = now.setMonth(now.getMonth() - i);
        let date = new Date(newdate);
        date =
          date.toLocaleString(this.locale === "he" ? "he-IL" : "default", {
            month: "short",
          }) +
          "-" +
          date.getFullYear().toString().substr(-2);
        this.xAxisMonths.push(date);
      }
      // get selected customer group id
      let customerGroupIds = [];
      this.selectedCustomerGroup.filter((item) => {
        customerGroupIds.push(item.id);
      });

      // get customers based on customer group id
      let customerIds = [];
      if (customerGroupIds.length > 0) {
        let customers = await this.$store.dispatch("customer/GetCustomer", {
          where_in:
            customerGroupIds.length > 0
              ? "group_id|[" + customerGroupIds + "]"
              : null,
        });
        // get customers ids based on selected customer group
        customers.filter((item) => {
          customerIds.push(item.id);
        });
      }
      // End ***** //
      // Start ***** //
      // get selected item group id
      let itemGroupIds = [];
      this.selectedItemGroup.filter((item) => {
        itemGroupIds.push(item.id);
      });
      let itemIds = [];
      if (itemGroupIds.length > 0) {
        // get item based on item group id
        let items = await this.$store.dispatch("product/GetProduct", {
          where_in:
            itemGroupIds.length > 0 ? "group_id|[" + itemGroupIds + "]" : null,
        });
        // get item ids based on selected item group
        items.filter((item) => {
          itemIds.push(item.id);
        });
      }
      // End ***** //
      this.lineChartData.quotations.sumOfAllDoc = [];
      this.lineChartData.quotations.sumOfClosedDoc = [];
      this.lineChartData.quotations.sumOfCancelledDoc = [];
      this.lineChartData.orders.sumOfAllDoc = [];
      this.lineChartData.orders.sumOfClosedDoc = [];
      this.lineChartData.invoices.sumOfAllDoc = [];
      this.lineChartData.invoices.sumOfCancelledDoc = [];
      this.lineChartData.receipts.sumOfAllDoc = [];
      if (this.chartType === "quotations") {
        // get quotations data based on filters and set it in charts
        await this.$store.dispatch("quotation/GetChartQuotation", {
          where: this.selectedEmployee?.id
            ? "employee_id|" + this.selectedEmployee?.id
            : this.selectedEmployee?.id,
          where_in:
            customerIds.length > 0 ? "customer_id|[" + customerIds + "]" : null,
        });
        let allTotalArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let closedTotalArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let cancelledTotalArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.quotationList.forEach((element) => {
          // START*****
          // check item group's item present in document
          let itemExistInQuotation = false;
          if (itemIds.length > 0) {
            element.quotationItems?.find((item) => {
              if (itemIds.includes(item.item_id)) {
                itemExistInQuotation = true;
              }
            });
          }
          if (itemIds.length > 0 && !itemExistInQuotation) {
            element = Object.assign({});
          }
          // END******
          for (let i = 0; i <= 11; i++) {
            let now = new Date(this.filter_date);
            let newdate = now.setMonth(now.getMonth() - i);
            let date = new Date(newdate);
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay = firstDay.toLocaleDateString("sv-SE");
            let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            lastDate = lastDate.toLocaleDateString("sv-SE");
            if (
              element.quotation_date >= firstDay &&
              element.quotation_date <= lastDate
            ) {
              allTotalArray[i] =
                allTotalArray[i] || allTotalArray[i] == 0
                  ? parseFloat(
                      (
                        parseFloat(element.grand_total) +
                        parseFloat(allTotalArray[i])
                      ).toFixed(2)
                    )
                  : 0;
            }
          }
          if (element.status == 1) {
            for (let i = 0; i <= 11; i++) {
              let now = new Date(this.filter_date);
              let newdate = now.setMonth(now.getMonth() - i);
              let date = new Date(newdate);
              let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
              firstDay = firstDay.toLocaleDateString("sv-SE");
              let lastDate = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0
              );
              lastDate = lastDate.toLocaleDateString("sv-SE");
              if (
                element.quotation_date >= firstDay &&
                element.quotation_date <= lastDate
              ) {
                closedTotalArray[i] =
                  closedTotalArray[i] || closedTotalArray[i] == 0
                    ? parseFloat(
                        (
                          parseFloat(element.grand_total) +
                          parseFloat(closedTotalArray[i])
                        ).toFixed(2)
                      )
                    : 0;
              }
            }
          } else if (element.status == 2) {
            for (let i = 0; i <= 11; i++) {
              let now = new Date(this.filter_date);
              let newdate = now.setMonth(now.getMonth() - i);
              let date = new Date(newdate);
              let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
              firstDay = firstDay.toLocaleDateString("sv-SE");
              let lastDate = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0
              );
              lastDate = lastDate.toLocaleDateString("sv-SE");
              if (
                element.quotation_date >= firstDay &&
                element.quotation_date <= lastDate
              ) {
                cancelledTotalArray[i] =
                  cancelledTotalArray[i] || cancelledTotalArray[i] == 0
                    ? parseFloat(
                        (
                          parseFloat(element.grand_total) +
                          parseFloat(cancelledTotalArray[i])
                        ).toFixed(2)
                      )
                    : 0;
              }
            }
          }
        });
        this.lineChartData.quotations.sumOfAllDoc = allTotalArray.reverse();
        this.lineChartData.quotations.sumOfClosedDoc =
          closedTotalArray.reverse();
        this.lineChartData.quotations.sumOfCancelledDoc =
          cancelledTotalArray.reverse();
      }
      if (this.chartType === "orders") {
        // get orders data based on filters and set it in charts
        await this.$store.dispatch("order/GetChartOrder", {
          where: this.selectedEmployee?.id
            ? "employee_id|" + this.selectedEmployee?.id
            : this.selectedEmployee?.id,
          where_in:
            customerIds.length > 0 ? "customer_id|[" + customerIds + "]" : null,
        });
        let allTotalArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let closedTotalArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.orderList.forEach((element) => {
          // START*****
          // check item group's item present in document
          let itemExistInQuotation = false;
          if (itemIds.length > 0) {
            element.orderItems?.find((item) => {
              if (itemIds.includes(item.item_id)) {
                itemExistInQuotation = true;
              }
            });
          }
          if (itemIds.length > 0 && !itemExistInQuotation) {
            element = Object.assign({});
          }
          // END******
          for (let i = 0; i <= 11; i++) {
            let now = new Date(this.filter_date);
            let newdate = now.setMonth(now.getMonth() - i);
            let date = new Date(newdate);
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay = firstDay.toLocaleDateString("sv-SE");
            let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            lastDate = lastDate.toLocaleDateString("sv-SE");
            if (
              element.order_date >= firstDay &&
              element.order_date <= lastDate
            ) {
              allTotalArray[i] =
                allTotalArray[i] || allTotalArray[i] == 0
                  ? parseFloat(
                      (
                        parseFloat(element.grand_total) +
                        parseFloat(allTotalArray[i])
                      ).toFixed(2)
                    )
                  : 0;
            }
          }
          if (element.status == 1) {
            for (let i = 0; i <= 11; i++) {
              let now = new Date(this.filter_date);
              let newdate = now.setMonth(now.getMonth() - i);
              let date = new Date(newdate);
              let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
              firstDay = firstDay.toLocaleDateString("sv-SE");
              let lastDate = new Date(
                date.getFullYear(),
                date.getMonth() + 1,
                0
              );
              lastDate = lastDate.toLocaleDateString("sv-SE");
              if (
                element.order_date >= firstDay &&
                element.order_date <= lastDate
              ) {
                closedTotalArray[i] =
                  closedTotalArray[i] || closedTotalArray[i] == 0
                    ? parseFloat(
                        (
                          parseFloat(element.grand_total) +
                          parseFloat(closedTotalArray[i])
                        ).toFixed(2)
                      )
                    : 0;
              }
            }
          }
        });
        this.lineChartData.orders.sumOfAllDoc = allTotalArray.reverse();
        this.lineChartData.orders.sumOfClosedDoc = closedTotalArray.reverse();
      }
      if (this.chartType === "invoices") {
        // get invoices data based on filters and set it in charts
        await this.$store.dispatch("invoice/GetChartInvoice", {
          where: this.selectedEmployee?.id
            ? "employee_id|" + this.selectedEmployee?.id
            : this.selectedEmployee?.id,
          where_in:
            customerIds.length > 0 ? "customer_id|[" + customerIds + "]" : null,
        });
        let allTotalArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        let cancelledTotalArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.invoiceList.forEach((element) => {
          // START*****
          // check item group's item present in document
          let itemExistInQuotation = false;
          if (itemIds.length > 0) {
            element.invoiceItems?.find((item) => {
              if (itemIds.includes(item.item_id)) {
                itemExistInQuotation = true;
              }
            });
          }
          if (itemIds.length > 0 && !itemExistInQuotation) {
            element = Object.assign({});
          }
          // END******
          for (let i = 0; i <= 11; i++) {
            let now = new Date(this.filter_date);
            let newdate = now.setMonth(now.getMonth() - i);
            let date = new Date(newdate);
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay = firstDay.toLocaleDateString("sv-SE");
            let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            lastDate = lastDate.toLocaleDateString("sv-SE");
            if (
              element.invoice_date >= firstDay &&
              element.invoice_date <= lastDate
            ) {
              allTotalArray[i] =
                allTotalArray[i] || allTotalArray[i] == 0
                  ? parseFloat(
                      (
                        parseFloat(element.grand_total) +
                        parseFloat(allTotalArray[i])
                      ).toFixed(2)
                    )
                  : 0;
              if (element.balance > 0) {
                cancelledTotalArray[i] =
                  cancelledTotalArray[i] || cancelledTotalArray[i] == 0
                    ? parseFloat(
                        (
                          parseFloat(element.grand_total) +
                          parseFloat(cancelledTotalArray[i])
                        ).toFixed(2)
                      )
                    : 0;
              }
            }
          }
        });
        this.lineChartData.invoices.sumOfAllDoc = allTotalArray.reverse();
        this.lineChartData.invoices.sumOfCancelledDoc =
          cancelledTotalArray.reverse();
      }
      if (this.chartType === "receipts") {
        // get receipts data based on filters and set it in charts
        await this.$store.dispatch("receipt/GetChartReceipt", {
          where: this.selectedEmployee?.id
            ? "employee_id|" + this.selectedEmployee?.id
            : this.selectedEmployee?.id,
          where_in:
            customerIds.length > 0 ? "customer_id|[" + customerIds + "]" : null,
        });
        let allTotalArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.receiptList.forEach((element) => {
          for (let i = 0; i <= 11; i++) {
            let now = new Date(this.filter_date);
            let newdate = now.setMonth(now.getMonth() - i);
            let date = new Date(newdate);
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            firstDay = firstDay.toLocaleDateString("sv-SE");
            let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            lastDate = lastDate.toLocaleDateString("sv-SE");
            if (
              element.receipt_date >= firstDay &&
              element.receipt_date <= lastDate
            ) {
              allTotalArray[i] =
                allTotalArray[i] || allTotalArray[i] == 0
                  ? parseFloat(
                      (
                        parseFloat(element.grand_total) +
                        parseFloat(allTotalArray[i])
                      ).toFixed(2)
                    )
                  : 0;
            }
          }
        });
        this.lineChartData.receipts.sumOfAllDoc = allTotalArray.reverse();
      }
      // set data in charts
      this.getChartOption(!this.barChart ? "line" : "bar");
    },
  },
};
</script>
<style scoped>
.card-box {
  box-shadow: 0px 0px 0px 2px rgb(94 86 105 / 10%) !important;
}
.quotations-box-text {
  display: flex;
  gap: 12px;
  justify-content: space-between;
}
::v-deep .multi-select-field .v-select__slot {
  width: 172px;
  height: 33px;
  overflow: auto hidden;
  align-items: unset;
  padding: 0 4px;
}
::v-deep .multi-select-field .v-select__selections {
  display: contents;
}
::v-deep .multi-select-field .v-select__selections .v-chip--select {
  display: inline-table;
}
::v-deep .multi-select-field .v-select__slot::-webkit-scrollbar {
  height: 2px;
}
::v-deep .multi-select-field .v-input__slot {
  width: fit-content;
}
</style>

<template>
  <v-card class="mx-auto overflow-y-auto" width="325" max-height="400px">
    <v-card-title>{{ $t("route.personalMenu") }} {{user.email.split('@')[0]}}</v-card-title>
    <v-list dense link>
      <v-list-item
        :to="selectMenu.to"
        v-for="(selectMenu, index) in menuList"
        :key="index"
        class="text-start text-body-2"
      >
        {{ $t(`route.${selectMenu.slug}`) }}
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PersonalMenu",
  computed: {
    ...mapGetters({
      user: "user",
      menuList: "menuList"
    }),
  },
  mounted() {
    this.$store.dispatch('GetPersonalMenuList', this.user.id)
  }
};
</script>

<style scoped>
::v-deep .v-treeview-node__label {
  flex: none !important;
}
::v-deep a:-webkit-any-link {
  text-decoration: none !important;
  color: #224a7d;
  font-weight: 400;
}
</style>
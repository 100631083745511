import { render, staticRenderFns } from "./PersonalMenu.vue?vue&type=template&id=70c90802&scoped=true&"
import script from "./PersonalMenu.vue?vue&type=script&lang=js&"
export * from "./PersonalMenu.vue?vue&type=script&lang=js&"
import style0 from "./PersonalMenu.vue?vue&type=style&index=0&id=70c90802&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c90802",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardTitle,VList,VListItem})

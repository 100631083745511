<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="my-auto support img rounded-lg white elevation-4" v-bind="attrs" v-on="on" :style="locale === 'he' ? 'left: 13px' : 'right:13px'">
          <span class="d-flex flex-row">
            <v-img class="my-auto" max-height="70" max-width="70" src="helpdesk.jpg"></v-img>
            <div class="d-flex align-start flex-column pa-2 data">
              <div @click="whatsapp">
                <v-icon color="green">mdi-whatsapp</v-icon>
                <span class="detail body-2">+972 549617200</span>
              </div>
              <div>
                <v-icon color="blue" class="my-1">mdi-phone</v-icon>
                <span class="detail body-2">+972 37361150</span>
              </div>
              <div @click="email">
                <v-icon color="red" class="my-1">mdi-email</v-icon>
                <span class="detail body-2">Bina@binaw.com</span>
              </div>
              <div @click="site">
                <v-icon color="black">mdi-web</v-icon>
                <span class="detail body-2">www.binaw.com</span>
              </div>
            </div>
          </span>
        </div>
      </template>
    </v-menu>
    <v-card class="new-icon-card mt-4" :style="locale === 'he' ? 'left: 13px' : 'right:13px'">
      <v-card-text class="d-flex align-center">
        <v-img class="my-auto" width="25px" max-height="90" max-width="90" src="Accessibilty.svg"></v-img>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "Support",
  computed: {
    ...mapGetters({
      locale: 'locale'
    })
  },
  methods: {
    whatsapp() {
      window.open("https://api.whatsapp.com/send?phone=%20972549617200");
    },
    email() {
      window.open("mailto:Bina@binaw.com");
    },
    site() {
      window.open("https://www.binaw.com/");
    }
  }
};
</script>

<style scoped>
.img {
  position: fixed;
}
.support:hover .detail {
  display: inline;
}
.detail {
  display: none;
}
.new-icon-card {
  position: fixed;
  right: 13px;
  top: 400px;
  width: 50px;
  height: 52px;
  cursor: pointer;
  background-color: #5bc0de;
}
</style>
<template>
  <v-container>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="dialog"
          persistent
          transition="dialog-top-transition"
          max-width="600"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
              height="50px"
            >
              <v-toolbar-title>{{ $t('dashboard.welcomeToDashboard') }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  icon
                  dark
                  @click="closeDialog()"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-list
              three-line
              subheader
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold primary--text text--darken-1">{{ $t('dashboard.welcomeToBina') }}</v-list-item-title>
                  <!-- <v-list-item-subtitle>Set the content filtering level to restrict apps that can be downloaded</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'WelcomeDialog',
    props: ["dialog"],
    data() {
      return {
        // dialog: true,
      }
    },
    methods: {
      closeDialog(){
        this.$emit('update:dialog', !this.dialog);
      }
    }
  }
</script>
